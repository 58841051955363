@import './../../../../shop/assets/styles/variables.scss';
@import '~@mcaskill/sass-mq';

.checkbox-wrapper {
  display: flex;
  align-items: center;
  gap: 14px;

  .checkbox {
    width: 15px;
    height: 15px;
    border: 2px solid rgba(255, 255, 255, 0.6);
    cursor: pointer;
    @media screen and (-ms-high-contrast: active),
      screen and (-ms-high-contrast: none) {
      margin-right: 10px;
    }

    &:focus-visible,
    &:focus,
    &:active,
    &:focus-within {
      outline: none;
      border: 2px solid #ffffff;
    }
  }
  .checked {
    border: 2px solid #ffffff;
    display: flex;
    justify-items: center;
    align-items: center;
    img {
      margin: auto;
      position: relative;
      user-select: none;
    }
  }
  .visually-hidden {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
  }

  label {
    font-size: 15px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.03em;
    text-align: left;
    cursor: pointer;
  }
}

@include mq($until: mobile) {
  .checkbox-wrapper {
    label {
      font-size: 12px;
      font-weight: 400;
      letter-spacing: 0em;
      text-align: left;
      cursor: pointer;
    }
  }
}
